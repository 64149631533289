body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  position: relative;
  height: 100vh;
  background-color: #1d222a;
}

$base-color1: #292d39;
$base-color2: #222831;
$base-color3: #393e46;
$accent-color1: #db5200;
$accent-color2: #ff8600;
$accent-color3: #ffd369;
$highlight-color1: #606980;
$highlight-color2: #eeeeee;
$highlight-color3: #ffffff;

$bannerHeight: 50;

.banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $bannerHeight + 0px;
  width: 100%;
  z-index: 1000;
  background-color: $base-color2;

  .banner-button {
    margin: 10px;
    float: left;
  }

  .banner-logo {
    margin: 10px;
    margin-right: 120px;
    float: right;
  }
}

:export {
  bannerHeight: $bannerHeight;
}

.App-body {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
}

$SidePanel-width: 400px;
$custom-border: solid 1px $base-color1;

.SidePanel {
  position: absolute;
  background-color: $base-color2;
  color: $highlight-color2;
  top: 0;
  width: $SidePanel-width;
  right: 0;
  bottom: 0;
  border: $custom-border;
  z-index: 999;
  text-align: center;
  overflow-y: scroll;
}

.Leva-container > div {
  position: relative;
  margin: auto;
  width: 100%;
  padding: 10px;
}

.simple-tabpanel-0 {
  padding: 100px;
}

.Scene-container {
  height: 500px;
}

// Your variable overrides
$sidebar-color: $accent-color3 !default;
$sidebar-width: 100% !default;
@import '~react-pro-sidebar/dist/scss/styles.scss';

// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #454651;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $accent-color3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $highlight-color2;
}

// ------------------------------------
// Status Panel styling
.StatusPanel {
  display: block;
  background-color: $base-color1;
  // height: 170px;
}

.StatusPanel .StatusPanel-play-button {
  width: 100%;
  padding: 10px;
}

.StatusPanel .StatusPanel-button {
  margin-bottom: 4px;
}

.StatusPanel .StatusPanel-hide-scene-button {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 6px;
  margin-right: 6px;
}

.StatusPanel .WebSocketUrlField {
  // display: block;
  // float: left;
  margin: 10px;
}

.StatusPanel-metrics {
  background-color: $base-color2;
  margin: 5px;
  padding: 10px;
}

.StatusPanel-metrics div {
  position: relative;
  font-size: 14px;
  display: block;
  text-align: left;
  margin-top: 3px;
  margin-left: 5px;
}

// ------------------------------------
// Landing Modal styling

.LandingModal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px; // 400
  background-color: $base-color2;
  border: 2px solid #000;
  //   box-shadow: 24px 24px;
  padding: 32px;
  overflow-y: auto;
  height: auto;
  max-height: 90%;
}

.LandingModal-box .WebSocketUrlField {
  width: 100%;
}

.LandingModal-box .LandingModal-button {
  margin: 3px;
}

// ------------------------------------
// Viewport Controls Modal styling

.ViewportControlsModal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px; // 400
  background-color: $base-color2;
  border: 2px solid #000;
  //   box-shadow: 24px 24px;
  padding: 32px;
  overflow-y: auto;
  height: auto;
}

// ------------------------------------
// Render Modal styling

.RenderModal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px; // 400
  background-color: $base-color2;
  border: 2px solid #000;
  //   box-shadow: 24px 24px;
  padding: 32px;
}

.RenderModal-code {
  padding: 10px;
  background-color: $base-color3;
  overflow-x: scroll;
  font-family: monospace;
  white-space: pre;
  margin-top: 16px;
}

.RenderModal-inline-code {
  font-size: 0.9rem;
  color: $accent-color3;
}

.RenderModal-box .RenderModal-button {
  width: 50%;
  padding: 5px;
  margin-top: 20px;
}

// ------------------------------------
// Load Path Modal styling

.LoadPathModal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px; // 400
  background-color: $base-color2;
  border: 2px solid #000;
  padding: 32px;
  margin-top: 20px;
}

.LoadPathModal-upload_button {
  text-align: center;
}

// ------------------------------------
// Camera Pane

.CameraPanel-props {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.CameraPanel-path-row {
  display: inline-block;
  width: 45%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.CameraPanel-row {
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.CameraPanel-top-button {
  width: 100%;
}

.CameraPanel-render-button {
  display: inline-block;
  width: 92%;
  margin: 4px;
  margin-top: 8px;
}

.CameraPanel-slider-container {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.CameraPanel-slider-button-container {
  margin-bottom: 10px;

  button {
    margin: 4px;
  }
}

.CameraList-container {
  position: relative;
  padding: 2px;
}

.CameraList-row {
  background: $base-color1;
  padding: 4px;
  border: solid 1px $highlight-color1;
  text-align: left;
}

.CameraList-row-buttons {
  display: inline-block;
  float: right;
}

.CameraList-row-time-interval > div {
  display: inline-block;
  text-align: center;
  margin: 10px;
  width: 100px;
}

.CameraList-row-animation-properties > div {
  display: inline-block;
  text-align: center;
  margin: 10px;
  width: 100px;
}

// ------------------------------------
// Viewer Window

.canvas-container-main,
.RenderWindow,
.ViewerWindow-render-crop-container {
  position: absolute;
  top: 0px;
  right: $SidePanel-width;
  bottom: 0px;
  left: 0px;
  border: $custom-border;
}

#not-connected-overlay {
  position: absolute;
  background-color: #1d222a;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  z-index: 10;
  opacity: 0.8;
}

#not-connected-overlay-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #393939;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ff6969;
  font-size: 18px;
  z-index: 10;
}

.ViewerWindow-render-crop-container {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-container-main {
  z-index: 3;
}

.ViewerWindow-render-crop {
  display: flex;
  box-shadow: rgb(0 0 0 / 70%) 0px 0px 0px 1600px;
  z-index: 1;
  vertical-align: middle;
}

$viewer_button_size: 50px;

.ViewerWindow-camera-toggle {
  position: absolute;
  margin: auto;
  text-align: center;
  margin-top: 10px;
  z-index: 1000;
  left: 0;
  right: 0;
}

.ViewerWindow-buttons {
  position: absolute;
  margin: 10px;
  top: 0px;
  right: $SidePanel-width;
  z-index: 1000;
  width: $viewer_button_size;
}

.ViewerWindow-iconbutton {
  margin-top: 5px;
}

// ------------------------------------
// Export panel styling

.ExportModal-code {
  padding: 10px;
  background-color: $base-color3;
  overflow-x: scroll;
  font-family: monospace;
  white-space: pre;
  margin-top: 16px;
  font-size: 0.9em;
}

.ExportModal-text {
  text-align: left;
  margin-top: 20px;
  font-size: 0.9em;
}

.ExportModal-button {
  // margin: 15px;
  padding: 20px;
}


#background-image, canvas {
  image-rendering: optimizeSpeed;             /*                     */
  image-rendering: -moz-crisp-edges;          /* Firefox             */
  image-rendering: -o-crisp-edges;            /* Opera               */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: pixelated;                 /* Chrome as of 2019   */
  image-rendering: optimize-contrast;         /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
}
